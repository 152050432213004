import React from 'react'

import { Layout } from './styles'

export type GridProps = {
    // grid gap in px
    gap?: number
    // refer to css grid-template-columns.
    // numbers are automatically interpreted in px.
    // [12, "1fr", 1fr] or [200, "200px", 200]
    // false elements are skipped
    col?: (number | string)[] | (number | string)[][]
    // refer to css grid-template-rows
    // numbers are automatically interpreted in px.
    // [12, "1fr", 1fr] or [200, "200px", 200]
    // false elements are skipped
    row?: (number | string)[] | (number | string)[][]
    children: React.ReactNode
    // breakpoint
    bp?: number | string
    className?: string
    /**
     *  x centers grid elements only with justify-content css property
     *  y centers grid elements only with align-items css property
     *  xy centers grid element with justify-content and align-items properties
     */
    center?: 'x' | 'y' | 'xy'
    /**
     *  Element width
     */
    w?: string | number
    maxW?: string | number
    h?: string | number
    /**
     *  auto fit layout
     */
    auto?: [min: number | string, max: number | string]
    onClick?: React.ReactEventHandler<HTMLDivElement>
    id?: string
    role?: string
}

const Grid = ({ children, col, row, ...props }: GridProps): JSX.Element => {
    return (
        <Layout
            col={(col as [])?.filter(element => element !== false)}
            row={(row as [])?.filter(element => element !== false)}
            {...props}
        >
            {children}
        </Layout>
    )
}

export default Grid
