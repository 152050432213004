import React from 'react'

import { Dropdown as AntdDropdown } from 'antd'

const Dropdown = ({ children, items, onOpenChange, trigger, placement }: DropdownProps): JSX.Element => {
    return (
        <AntdDropdown menu={{ items }} onOpenChange={onOpenChange} trigger={trigger} placement={placement} arrow>
            {children}
        </AntdDropdown>
    )
}

export default Dropdown
