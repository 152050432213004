import React from 'react'

import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import useRouter from 'utils/hook/useRouter'

import Space from 'components/ui/layout/space'
import Hide from 'components/ui/responsive/hide'

import { Wrapper, Item, row } from './styles'

const Navigation = (): JSX.Element => {
    const router = useRouter()
    const { t } = useTranslation()

    const routes = [
        {
            to: router.routes.home,
            label: t('navigation.home')
        },
        {
            to: router.routes.account.skills,
            label: t('navigation.skills')
        },
        {
            to: router.routes.account.playlists,
            label: t('navigation.contents')
        }
    ]

    return (
        <Wrapper>
            <Hide bp={830}>
                <Space size={60} className={row}>
                    {routes.map(({ to, label }) => (
                        <Link href={to} key={label} legacyBehavior passHref>
                            <Item selected={to === router.pathname}>
                                {label}
                            </Item>
                        </Link>
                    ))}
                </Space>
            </Hide>
        </Wrapper>
    )
}

export default Navigation
