import React from 'react'

import { BiBookOpen } from '@react-icons/all-files/bi/BiBookOpen'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { RiSurveyLine } from '@react-icons/all-files/ri/RiSurveyLine'
import { VscChecklist } from '@react-icons/all-files/vsc/VscChecklist'

type MenuCompanyType = {
    label: string
    icon: JSX.Element
    to?: string
    options?: { label: string, to: string }[]
}[]

const MenuCompany = (t: (key: string) => string, routes: any): MenuCompanyType => [
    {
        label: t('navigation.company.collaborators'),
        icon: <FiUsers size={20} />,
        to: routes.company.collaborators.list
    },
    {
        label: t('navigation.company.training_sheets'),
        icon: <BiBookOpen size={20} />,
        to: routes.company.trainingSheets.list
    },
    {
        label: t('navigation.company.evaluations'),
        icon: <RiSurveyLine size={20} />,
        to: routes.company.evaluations.list
    },
    {
        label: t('navigation.company.tasks'),
        icon: <VscChecklist size={20} />,
        to: routes.company.tasks.list
    }
]

export default MenuCompany
