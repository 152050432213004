import React from 'react'

import { useTranslation } from 'react-i18next'

import { useUser } from 'context/user'

import Logo from 'assets/logo.svg'

import useRouter from 'utils/hook/useRouter'
import { languages } from 'utils/i18n'

import Dropdown from 'components/ui-legacy/dropdown'
import Badge from 'components/ui/badge'
import Container from 'components/ui/layout/container'
import Space from 'components/ui/layout/space'
import Link from 'components/ui/link'

import Account from './account'
import Navigation from './navigation'
import { Content, container, header } from './styles'

type HeaderProps = {
    navigation?: boolean
}

const Header = ({ navigation }: HeaderProps): JSX.Element => {
    const router = useRouter()
    const { i18n } = useTranslation()
    const user = useUser()

    const setLocale = language => {
        i18n.changeLanguage(language)
        router.reload()
    }

    return (
        <header className={header} suppressHydrationWarning>
            <Container className={container}>
                <Content>
                    <Space center size={20}>
                        {user ? <Link to={router.routes.home}><Logo /></Link> : <Logo />}
                        <Dropdown
                            position="right"
                            options={
                                languages.map(language => ({
                                    label: language.toUpperCase(),
                                    value: language,
                                    action: () => setLocale(language)
                                }))
                            }
                            type="text"
                            textColor='primary'
                        >
                            <Badge>{i18n.language.toUpperCase()}</Badge>
                        </Dropdown>

                    </Space>
                    {navigation && <Navigation />}
                    {user && <Account user={user} />}
                </Content>
            </Container>
        </header>
    )
}

Header.defaultProps = {
    navigation: true
}

export default Header
