import styled from '@emotion/styled'

type SeparatorType = {
  // target last element
  isLast: boolean
  axis: 'x' | 'y',
  size: number,
}

export const Separator = styled.div<SeparatorType>`
  display: flex;
  align-items: center;
  ${({ axis }) => axis === 'x' ? 'margin-right' : 'margin-bottom'}: ${({ isLast, size }) => (isLast && (size >= 0) ? 0 : size) + 'px'};
`

export const Container = styled.div<{axis: 'x' | 'y', center: boolean}>`
  display: inline-flex;
  flex-direction: ${({ axis }) => axis === 'x' ? 'row' : 'column'};
  ${({ axis }) => axis === 'x' ? 'align-items' : 'justify-content'}: ${({ center }) => center && 'center'};
`
