import React from 'react'

import { AiOutlineHistory } from '@react-icons/all-files/ai/AiOutlineHistory'
import { AiOutlineLogout } from '@react-icons/all-files/ai/AiOutlineLogout'
import { AiOutlineTrophy } from '@react-icons/all-files/ai/AiOutlineTrophy'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { MdFavoriteBorder } from '@react-icons/all-files/md/MdFavoriteBorder'
import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown'
import { MdPeopleOutline } from '@react-icons/all-files/md/MdPeopleOutline'
import { MdPlaylistPlay } from '@react-icons/all-files/md/MdPlaylistPlay'
import { VscSettings } from '@react-icons/all-files/vsc/VscSettings'
import { useTranslation } from 'react-i18next'

import { removeToken } from 'utils/auth'
import { ROLE_ADMIN_COMPANY, ROLE_ADMIN } from 'utils/constants/roles'
import useBreakpoint from 'utils/hook/useBreakpoint'
import useRouter from 'utils/hook/useRouter'
import { isGranted } from 'utils/user'

import Avatar from 'components/ui/avatar'
import Dropdown from 'components/ui/dropdown'
import Space from 'components/ui/layout/space'

import { Row, RowAdmin, RowCompany } from './style'

type AccountProps = {
    user: {
        firstName: string,
        lastName: string,
        avatar: {
            color: string,
        }
    },
    fullName?: boolean
}

const Account = ({ user }: AccountProps): JSX.Element => {
    const { t } = useTranslation()
    const router = useRouter()
    const options = []

    const isMobile = useBreakpoint(931)

    isGranted(ROLE_ADMIN) && options.push({
        key: 'admin',
        label: <RowAdmin onClick={() => router.push(router.routes.admin.collaborators.list)}><VscSettings size={20} />{t('navigation.admin.title')}</RowAdmin>
    })

    isGranted(ROLE_ADMIN_COMPANY) && options.push({
        key: 'company',
        label: <RowCompany onClick={ () => router.push(router.routes.company.collaborators.list)}><FiUsers size={20} />{t('navigation.company.title')}</RowCompany>
    })

    options.push({
        key: 'favorites',
        label: <Row onClick={() => router.push(router.routes.account.favorites)}><MdFavoriteBorder size={20} />{t('navigation.favorites')}</Row>
    },
    {
        key: 'communities',
        label: <Row onClick={() => router.push(router.routes.account.communities)}><MdPeopleOutline size={20} />{t('navigation.communities')}</Row>
    },
    {
        key: 'playlists',
        label: <Row onClick={() => router.push(router.routes.account.playlists)}><MdPlaylistPlay size={20} />{t('navigation.playlists')}</Row>
    },
    {
        key: 'skills',
        label: <Row onClick={() => router.push(router.routes.account.skills)}><AiOutlineTrophy size={20} />{t('navigation.skills')}</Row>
    },
    {
        key: 'history',
        label: <Row onClick={() => router.push(router.routes.account.history)}><AiOutlineHistory size={20} />{t('navigation.history')}</Row>
    },
    {
        key: 'logout',
        label: (
            <Row
                onClick={() => {
                    removeToken()
                    router.push(router.routes.login)
                }}
            >
                <AiOutlineLogout size={20} />
                {t('navigation.logout')}
            </Row>
        )
    })

    return (
        <Dropdown items={options} trigger={['click']} placement="bottomLeft">
            <Space center data-clickable="true" role="button" size={5}>
                <Space center size={10}>
                    <Avatar firstName={user.firstName} lastName={user.lastName} avatar={user.avatar} fullName={false} />
                    {!isMobile && t('welcome.message', { firstName: user.firstName })}
                </Space>
                <MdKeyboardArrowDown />
            </Space>
        </Dropdown>
    )
}

export default Account
