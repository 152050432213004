import { useEffect, useState } from 'react'

import { useMediaQuery } from 'react-responsive'

const useBreakpoint = (breakpoint: number) => {
    const [isBreakpoint, setIsBreakpoint] = useState(false)
    const bp = useMediaQuery({ maxWidth: breakpoint })

    useEffect(() => {
        setIsBreakpoint(bp)
    }, [bp])

    return isBreakpoint
}

export default useBreakpoint
