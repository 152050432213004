import React from 'react'

import Ellipsis from './ellipsis'
import P from './paragraph'
import getStyle from './style'
import { Element } from './styles'
import Title from './title'

export type TextProps = StyleProps & {
    className?: string
    children: Children
    secondary?: boolean
    bold?: boolean
    italic?: boolean
    color?: string
    ta?: 'left' |'center' | 'right'
    ellipsis?: number | boolean
    onClick?: () => any
}

const Text = React.forwardRef((
    props: React.PropsWithChildren<TextProps>,
    ref: React.Ref<HTMLSpanElement>
) => {
    if (props.ellipsis) return <Ellipsis line={typeof props.ellipsis === 'number' ? props.ellipsis : 1} className={getStyle(props)} {...props}>{props.children}</Ellipsis>
    return <Element ref={ref} className={getStyle(props)} {...props}>{props.children}</Element>
})

Text.displayName = 'Text'

export default Object.assign(Text, {
    P,
    Title
})
