import * as React from 'react'

import { Container, Separator } from './style'

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  size?: number, // space in px between element
  axis?: 'x' | 'y', // direction of elements (x = horizontal, y = vertical)
  className?: string,
  center?: boolean,
  children?: React.ReactNode,
  split?: React.ReactNode | string,
}

type Ref = HTMLDivElement

const Space = React.forwardRef<Ref, Props>(({
    size = 10,
    axis = 'x',
    split,
    className,
    center,
    children,
    ...props
}: Props, ref) => {
    const len = React.Children.toArray(children).filter(children => children !== undefined).length

    if (len === 0) return
    if (len === 1) return className ? <div className={className}>{children}</div> : <React.Fragment>{children}</React.Fragment>

    return (
        <Container axis={axis} center={center} className={className} ref={ref} {...props}>
            {
                React.Children.map(children, (child, index) : React.ReactNode => {
                    const isLast = !(len - index - 1)
                    return (
                        <React.Fragment key={index}>
                            <Separator isLast={isLast} axis={axis} size={split ? size / 2 : size}>{child}</Separator>
                            {!isLast && split && <Separator isLast={isLast} axis={axis} size={split ? size / 2 : size}>{split}</Separator>}
                        </React.Fragment>
                    )
                })
            }
        </Container>

    )
})

Space.displayName = 'Space'
export default Space
