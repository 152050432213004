import React from 'react'

import Head from 'next/head'

import Grid from 'components/ui/layout/grid'
import Main from 'components/ui/layout/main'

import Footer from './footer'
import Header from './header'
import Menu from './menu'
import { Element } from './styles'

type Props = {
    background?: boolean
    children?: Children
    center?:boolean
    menu?: boolean | {type: 'admin' | 'company', open: boolean}
    navigation?: false
}

const Layout = ({ children, center, menu, navigation, background }: Props): JSX.Element => (
    <Element suppressHydrationWarning>
        <Head>
            <title>UnlimitEd Learning</title>
        </Head>
        <Header navigation={navigation} />
        <Main center={center} background={background}>
            <Grid col={menu ? ['auto', '1fr'] : ['1fr']} >
                {typeof menu !== 'boolean' && <Menu settings={menu} />}
                <div>
                    {children}
                </div>
            </Grid>
        </Main>
        <Footer />
    </Element>
)

Layout.defaultProps = {
    menu: false,
    background: true
}

export default Layout
