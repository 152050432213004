import React from 'react'

import { BiBookOpen } from '@react-icons/all-files/bi/BiBookOpen'
import { BsBuilding } from '@react-icons/all-files/bs/BsBuilding'
import { FiSettings } from '@react-icons/all-files/fi/FiSettings'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { RiSurveyLine } from '@react-icons/all-files/ri/RiSurveyLine'
import { SiSonarsource } from '@react-icons/all-files/si/SiSonarsource'
import { VscChecklist } from '@react-icons/all-files/vsc/VscChecklist'

type MenuAdminType = {
    label: string
    icon: JSX.Element
    to?: string
    options?: { label: string, to: string }[]
}[]

const MenuAdmin = (t: (key: string) => string, routes: any): MenuAdminType => [
    {
        label: t('navigation.admin.sources.management'),
        icon: <SiSonarsource size={20} />,
        options: [
            {
                label: t('navigation.admin.sources.configuration'),
                to: routes.admin.sources.list
            },
            {
                label: t('navigation.admin.sources.websites'),
                to: routes.admin.sources.websites.list
            }
        ]
    },
    {
        label: t('navigation.admin.companies'),
        icon: <BsBuilding size={20} />,
        to: routes.admin.companies.list
    },
    {
        label: t('navigation.admin.collaborators'),
        icon: <FiUsers size={20} />,
        to: routes.admin.collaborators.list
    },
    {
        label: t('navigation.admin.training_sheets'),
        icon: <BiBookOpen size={20} />,
        to: routes.admin.trainingSheets.list
    },
    {
        label: t('navigation.admin.evaluations'),
        icon: <RiSurveyLine size={20} />,
        to: routes.admin.evaluations.list
    },
    {
        label: t('navigation.admin.tasks'),
        icon: <VscChecklist size={20} />,
        to: routes.admin.tasks.list
    },
    {
        label: t('navigation.admin.settings'),
        icon: <FiSettings size={20} />,
        options: [
            {
                label: t('navigation.admin.domains'),
                to: routes.admin.domains
            },
            {
                label: t('navigation.admin.sub_domains'),
                to: routes.admin.subDomains
            },
            {
                label: t('navigation.admin.skills'),
                to: routes.admin.skills
            },
            {
                label: t('navigation.admin.formats'),
                to: routes.admin.formats
            },
            {
                label: t('navigation.admin.interests'),
                to: routes.admin.interests
            },
            {
                label: t('navigation.admin.socio_professional_categories'),
                to: routes.admin.socioProfessionalCategories
            },
            {
                label: t('navigation.admin.emails'),
                to: routes.admin.emails
            }
        ]
    }
]

export default MenuAdmin
