import styled from '@emotion/styled'

import { setBreakpoint, setSize } from 'utils/style'

import { GridProps } from './'

const setCol = (columns: (string | number)[] | (string | number)[][] | unknown): string => {
    return Array.isArray(columns) && columns.map(column => setSize(column)).join(' ')
}

export const Layout = styled.div<GridProps>`
    display: grid;
    gap: ${({ gap }) => setSize(gap)};
    grid-template-columns: ${({ col, bp }) => setCol(bp ? col[0] : col)};
    grid-template-rows: ${({ row, bp }) => row && setCol(bp ? row[0] : row)};
    width: ${({ w }) => setSize(w)};
  height: ${({ h }) => setSize(h)};
    ${({ bp, col }) => bp && setBreakpoint(bp, `grid-template-columns: ${setCol(col[1])};`)}
    ${({ center }) => center &&
            {
                x: 'align-items: center;',
                y: 'justify-content: center;',
                xy: 'justify-content: center; align-items: center;'
            }[center]}
    
  ${({ auto }) => auto && `grid-template-columns: repeat(auto-fit, minmax(${setSize(auto[0])}, ${setSize(auto[1])}));`}
`
