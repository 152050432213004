import styled from '@emotion/styled'

import theme from 'settings/theme'

export const Content = styled.div`
  font-size: ${theme.link.fs}px;
  font-weight: ${theme.link.fw};
  color: ${theme.link.color};
  &, a {
    transition: all 200ms;
  }
  &:hover, a:hover {
    color: ${theme.link.hoverColor};
  }
`
