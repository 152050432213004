import React, { useEffect, useRef, useState } from 'react'

import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown'
import { Transition } from 'react-transition-group'

import useRouter from 'utils/hook/useRouter'

import Link from 'components/ui/link'

import {
    Item,
    IconWrapper,
    Category,
    Options,
    Row,
    IconWrapperArrow,
    OptionsWrapper
} from './styles'

type OptionType = {
    label: string,
    to: string,
}

type MenuRowType = {
    label: string,
    icon: JSX.Element,
    options?: OptionType[],
    open: boolean,
    to?: string,
    className?: string,
}

const MenuRow = ({ label, icon, options, to, open, className }: MenuRowType): JSX.Element => {
    const { pathname } = useRouter()
    const [isCollapsed, setCollapsed] = useState<boolean>(false)
    const active = !!to?.includes(pathname)

    useEffect(() => {
        if (!open) setCollapsed(false)
    }, [open])

    const toggle = (): void => setCollapsed(!isCollapsed)
    const contentRef = useRef<HTMLDivElement>(null)

    return (
        <Row active={active} className={className}>
            <Transition in={isCollapsed} timeout={0}>
                {state => to ? (
                    <Link to={to}>
                        <Item active={active}>
                            <IconWrapper>{icon}</IconWrapper>
                            <Category>{label}</Category>
                        </Item>
                    </Link>
                ) : (
                    <>
                        <Item onClick={toggle}>
                            <IconWrapper>{icon}</IconWrapper>
                            <Category>{label}</Category>
                            {options && (
                                <IconWrapperArrow state={state}>
                                    <MdKeyboardArrowDown />
                                </IconWrapperArrow>
                            )}
                        </Item>
                        {
                            options && (
                                <OptionsWrapper
                                    state={state}
                                    contentHeight={contentRef?.current?.offsetHeight}
                                >
                                    <Options ref={contentRef}>
                                        {
                                            options.map((option, index) => (
                                                <Link to={option.to} key={index}>
                                                    {option.label}
                                                </Link>
                                            ))
                                        }
                                    </Options>
                                </OptionsWrapper>
                            )
                        }
                    </>
                )}
            </Transition>
        </Row>
    )
}

export default MenuRow
