import jwtDecode from 'jwt-decode'

import { DecodedToken, decodeToken, getToken } from '../auth'

export interface User {
    readonly firstName: string
    readonly lastName: string
    readonly company: string
    readonly image?: string
    readonly id: string
    avatar: {
        url: undefined | null
        color: string
    }
}

export const avatarColors = [
    '#236ca8',
    '#23a894',
    '#2ca823',
    '#a88d23',
    '#a86e23',
    '#a83b23',
    '#a82323',
    '#9623a8',
    '#4023a8'
]

export const getRandomAvatarColor = (): string => avatarColors[Math.floor(Math.random() * avatarColors.length)]

export const getUser = (): User => decodeToken(getToken())
export const getRoles = (token: string): DecodedToken['roles'] => token ? jwtDecode<{roles: DecodedToken['roles']}>(token).roles : []
export const isGranted = (role: string, token?: string): boolean => getRoles(token || getToken()).includes(role)
