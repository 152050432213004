import styled from '@emotion/styled'

import { colors } from 'settings/theme'

export const Row = styled.div`
  display: grid;
  grid-template-columns: 30px minmax(150px, 1fr);
  align-items: center;
`

export const RowAdmin = styled(Row)`
  color: ${colors.error};
`

export const RowCompany = styled(Row)`
  color: ${colors.primary};
`
