import styled from '@emotion/styled'

export const Wrapper = styled.div<{line: number}>`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ line }) => line || 1};
  -webkit-box-orient: vertical;
`
