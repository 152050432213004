import React from 'react'

import IconLoader from 'assets/icons/loader.svg'

import { ButtonType } from 'utils/types/button'

import { ButtonWrapper, LoadingWrapper, Content, IconWrapper, NoChild, IconButtonWrapper } from './style'

const Button = React.forwardRef((
    {
        children,
        secondary,
        disabled,
        loading,
        color,
        type,
        icon,
        small,
        variant,
        ...props
    }: ButtonType,
    ref: React.Ref<HTMLButtonElement>
): JSX.Element => {
    if (!children && icon && variant !== 'icon') {
        return <NoChild
            role="button"
            isLoading={loading}
        >
            {loading ? <IconLoader {...icon.props} /> : icon}
        </NoChild>
    }

    const Wrapper = ({
        icon: IconButtonWrapper
    })[variant] || ButtonWrapper

    return (
        <Wrapper
            ref={ref}
            small={small}
            disabled={loading || disabled}
            c={color}
            {...{
                secondary,
                isLoading: loading,
                type,
                ...props
            }}
        >
            <Content>
                {loading && <LoadingWrapper><IconLoader /></LoadingWrapper>}
                {icon && !loading && <IconWrapper>{icon}</IconWrapper>}
                {variant !== 'icon' && children}
            </Content>
        </Wrapper>
    )
})

Button.displayName = 'Button'

Button.defaultProps = {
    type: 'button'
}

export default Button
