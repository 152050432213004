import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import theme, { global, input } from 'settings/theme'

import { setSize } from 'utils/style'
import { ButtonType } from 'utils/types/button'

export const spin = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`

export const appear = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 0.75em;
  }
`

export const ButtonWrapper = styled.button<ButtonType & {isLoading?: boolean, c?: string}>`
  line-height: 0;
  z-index: 0;
  position: relative;
  font-size: ${theme.button.fs}px;
  font-weight: ${theme.button.fw};
  padding: ${({ small, rounded }) => !rounded && ('6px ' + (small ? theme.input.small.padding : 10 + theme.global.padding) + 'px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.button.br}px; 
  background-color: ${({ secondary, c }) => secondary ? 'transparent' : c || theme.button.bc};
  color: ${({ secondary, c }) => !secondary ? theme.button.color : c || theme.button.bc};
  border-style: solid;
  border-color: ${({ c }) => c || theme.button.bc};
  cursor: ${props => props.disabled ? 'no-drop' : 'pointer'};
  ${props => props.disabled && 'opacity: 0.7;'}
  ${props => props.isLoading && 'opacity: 0.8;'}
  transition: all 200ms var(--transition-base);
  user-select: none;
  touch-action: manipulation;
  border-width: 2px;
  filter: brightness(1);
  transform: scale(1);
  width: ${({ w, rounded }) => rounded ? '35px' : w ? setSize(w) : 'fit-content'};
  height: ${({ small }) => small ? theme.input.small.height : theme.input.height}px;
  box-shadow: ${global.shadowLight};

  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(1);
    transform: scale(0.98);
  }
`
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`

export const LoadingWrapper = styled(IconWrapper)`
  margin-right: 8px;
  animation: ${spin} 800ms infinite linear, ${appear} 200ms;
`

export const Content = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 5px;
`

export const NoChild = styled(IconWrapper)<{isLoading?: boolean}>`
  margin: 0;
  animation: ${({ isLoading }) => isLoading ? spin : null} 800ms infinite linear;
  border-radius: ${theme.button.br}px;
  cursor: pointer;
`

export const IconButtonWrapper = styled(ButtonWrapper)`
  padding: 0;
  height: ${({ small }) => small ? input.small.height : input.height}px;
  width:  ${({ small }) => small ? input.small.height : input.height}px;
`
