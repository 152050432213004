import { css } from '@emotion/css'
import styled from '@emotion/styled'

import theme, { zIndex } from 'settings/theme'

export const container = css`
  border-top: 1px solid #cbcbcb;
  background-color: ${theme.colors.grey};
  padding: 50px 0;
  width: 100%;
  z-index: ${zIndex.footer};
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
