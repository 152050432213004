import React from 'react'

import ShapeBottom from './assets/shape-bottom.svg'
import ShapeTop from './assets/shape-top.svg'
import { Element, ShapeWrapper } from './styles'

type Props = {
    children: Children
    center?: boolean
    background: boolean
}

const Main = ({ children, center, background }: Props): JSX.Element => {
    return (
        <Element center={center}>
            {background && (
                <ShapeWrapper>
                    <ShapeTop data-shape-top />
                    <ShapeBottom data-shape-bottom />
                </ShapeWrapper>
            )}
            {children}
        </Element>
    )
}

export default Main
