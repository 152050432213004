import { css } from '@emotion/css'

import theme from 'settings/theme'

const getMaxWidth = (fluid: boolean, shrink: boolean): string => {
    const { container } = theme
    // padding L + R = 2 * 16px
    const width = (shrink ? 800 : container.maxWidth) + 2 * container.padding
    return !fluid && `${width}px`
}

export const container = (fluid: boolean, shrink: boolean): string => css`
  margin: 0 auto;
  padding: ${!fluid && `0 ${theme.container.padding}px`};
  max-width: ${getMaxWidth(fluid, shrink)};
`
