import React from 'react'

import { IoIosClose } from '@react-icons/all-files/io/IoIosClose'

import { Element } from './styles'

type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode,
    color?: string | undefined,
    onClose?: () => void,
    animate?: boolean,
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
    ({
        children,
        color,
        onClose,
        animate,
        ...props
    },
    ref
    ): JSX.Element => (
        <Element
            background={color}
            {...props}
            ref={ref}
            closable={!!onClose}
            onClick={onClose}
            animate={animate}
        >
            {children}
            {onClose && <IoIosClose />}
        </Element>
    ))

Badge.displayName = 'Badge'

export default Badge
