import React from 'react'

import Logo from 'assets/logo.svg'

import Container from 'components/ui/layout/container'

import { container, Wrapper } from './styles'

const Footer = () :JSX.Element => {
    return (
        <footer className={container}>
            <Container>
                <Wrapper>
                    <Logo />
                </Wrapper>
            </Container>
        </footer>
    )
}

export default Footer
