import styled from '@emotion/styled'

export const Profile = styled.div<{c: string}>`
  background-color: ${({ c }) => c};
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
`

export const Wrapper = styled.div`
`
