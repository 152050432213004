import React from 'react'

import Space from 'components/ui//layout/space'
import Hide from 'components/ui/responsive/hide'

import { Profile, Wrapper } from './styles'

type AvatarProps = {
    firstName: string
    lastName: string
    avatar: {
        color: string
    },
    fullName: false
}

const Avatar = ({ firstName, lastName, avatar, fullName }: AvatarProps): JSX.Element => {
    const initial = `${firstName.charAt(0)}${lastName.charAt(0)}`

    return (
        <Wrapper>
            <Space center>
                <Profile c={avatar.color}>{initial}</Profile>
                {(fullName && (firstName || lastName)) && (
                    <Hide bp={931}>
                        {`${firstName} ${lastName}`}
                    </Hide>
                )}
            </Space>
        </Wrapper>
    )
}

Avatar.defaultProps = {
    fullName: true
}

export default Avatar
