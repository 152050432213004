import * as React from 'react'

import { cx } from '@emotion/css'

import { container } from './style'

export type Props = React.HTMLProps<HTMLDivElement> & {
    className?: string,
    children?: React.ReactNode,
    fluid?: boolean,
    shrink?: boolean,
}

type Ref = HTMLDivElement

const Container = React.forwardRef<Ref, Props>(({
    className,
    children,
    fluid,
    shrink,
    ...props
}: Props, ref) => {
    return (
        <div className={cx(container(fluid, shrink), className)} ref={ref} {...props}>
            {children}
        </div>
    )
})

Container.displayName = 'Col'
export default Container
