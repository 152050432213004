import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'

import { colors } from 'settings/theme'

const open = keyframes`
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
`

export const Element = styled.div<{background: string | undefined, closable?: boolean, animate: boolean}>`
  background-color: ${({ background }) => background || colors.primary};
  font-size: 13px;
  color: white;
  height: 20px;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ closable }) => !closable ? 10 : 4}px 0 10px;
  border-radius: 10px;
  line-height: 0;
  width: fit-content;
  display: inline-flex;
  cursor: ${({ closable }) => closable && 'pointer'};
  animation: ${({ animate }) => animate && open} 200ms forwards ease;
`
