import { css } from '@emotion/css'
import styled from '@emotion/styled'

import theme, { zIndex } from 'settings/theme'

import { setSize } from 'utils/style'

export const header = css`
  background-color: ${theme.header.bc};
  box-shadow: 0 3px 6px #2C170426;
  z-index: ${zIndex.header};
  position: fixed;
  width: 100%;
`

export const Content = styled.div`
  height: ${setSize(theme.header.height)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const container = css`
  width: 100%;
`
