import styled from '@emotion/styled'

import { header } from 'settings/theme'

export const Element = styled.main<{center?: boolean}>`
  flex: 1;
  width: 100%;
  margin-top: ${header.height}px;
  background-color: ${({ theme }) => theme.bc};
  z-index: 1;
  position: relative;
  ${({ center }) => center && 'display: grid;'}
`

export const ShapeWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F3EFEA;
  svg {
    position: absolute;
    transform: scale(0.6);
  }
  [data-shape-top] {
    top: -106px;
    left: -320px;
  }
  [data-shape-bottom] {
    bottom: -128px;
    right: -372px;
  }
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: transparent linear-gradient(180deg, #FFFFFF00 calc(100% - 150px), #FFFFFF 100%) 0 0 no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
  }
`
