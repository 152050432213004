import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { colors } from 'settings/theme'

export const Wrapper = styled.div`
  height: 100%;
  grid-gap: 60px;
`

export const Item = styled.a<{selected?: boolean}>`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 0 ${colors.primary + 0x20} solid;
    transition: all 200ms;
  }
  transition: all 200ms;
  &:hover {
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 6px ${colors.primary + 0x20} solid;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: ${({ selected: s }) => s ? 6 : 0}px ${colors.primary} solid;
  }
`

export const row = css`
  height: 100%;
`
