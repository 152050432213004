import React, { Children } from 'react'

import { Wrapper } from './styles'

type EllipsisProps = {
    children: Children
    line?: number
}
const Ellipsis = ({ children, line, ...props }: EllipsisProps): JSX.Element => {
    return (
        <Wrapper line={line} {...props}>
            {children}
        </Wrapper>
    )
}

export default Ellipsis
