
import React, { useState } from 'react'

import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { MdClose } from '@react-icons/all-files/md/MdClose'
import { MdMenu } from '@react-icons/all-files/md/MdMenu'
import { VscSettings } from '@react-icons/all-files/vsc/VscSettings'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-transition-group'

import { MENU_ADMIN, MENU_COMPANY } from 'utils/constants/menu'
import { ROLE_ADMIN, ROLE_ADMIN_COMPANY } from 'utils/constants/roles'
import useRouter from 'utils/hook/useRouter'
import { isGranted } from 'utils/user'

import menuAdmin from './admin'
import menuCompany from './company'
import MenuRow from './row'
import {
    Wrapper,
    MenuWrapper,
    DrawerButtonWrapper,
    MenuRowAdmin,
    MenuRowCompany
} from './styles'

type MenuProps = {
    settings?: {type: 'admin' | 'company', open: boolean},
}

const Menu = ({ settings }: MenuProps): JSX.Element => {
    const { t } = useTranslation()
    const { routes } = useRouter()
    const [open, setOpen] = useState<boolean>(settings.open)

    const ButtonIcon = open ? MdClose : MdMenu
    const handleBlur = (e: { relatedTarget: { nodeName: string } }): void => {
        if (e?.relatedTarget?.nodeName !== 'A') setOpen(false)
    }

    return (
        <Transition in={open} timeout={0}>
            {state => (
                <Wrapper state={state} w={300} onBlur={handleBlur} tabIndex={-1}>
                    <DrawerButtonWrapper>
                        <ButtonIcon onClick={() => setOpen(!open)} />
                    </DrawerButtonWrapper>
                    {(settings.type === MENU_COMPANY && isGranted(ROLE_ADMIN_COMPANY)) && (
                        <>
                            <MenuWrapper onClick={() => setOpen(true)}>
                                {menuCompany(t, routes).map((row) => <MenuRow {...row} key={row.label} open={open} />)}
                                {isGranted(ROLE_ADMIN) && (
                                    <MenuRowAdmin
                                        label={t('navigation.admin.title')}
                                        icon={<VscSettings size={20} />}
                                        to={routes.admin.collaborators.list}
                                        open={open}
                                    />
                                )}
                            </MenuWrapper>
                        </>
                    )}
                    {(settings.type === MENU_ADMIN && isGranted(ROLE_ADMIN)) && (
                        <>
                            <MenuWrapper onClick={() => setOpen(true)}>
                                {menuAdmin(t, routes).map((row) => <MenuRow {...row} key={row.label} open={open} />)}
                                {isGranted(ROLE_ADMIN_COMPANY) && (
                                    <MenuRowCompany
                                        label={t('navigation.company.title')}
                                        icon={<FiUsers size={20} />}
                                        to={routes.company.collaborators.list}
                                        open={open}
                                    />
                                )}
                            </MenuWrapper>
                        </>
                    )}
                </Wrapper>
            )}
        </Transition>

    )
}

export default Menu
