import { css } from '@emotion/css'

const getStyle = props => css`
  font-weight: ${props.bold && 700};
  color: ${props.color || null};
  font-style: ${props.italic && 'italic'};
  text-align: ${props.ta};
  font-size: ${props.fs && props.fs + 'px'};
`

export default getStyle
