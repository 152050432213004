import React from 'react'

import { TextProps } from '../'

const Title = React.forwardRef((
    props: React.PropsWithChildren<TextProps>,
    ref: React.Ref<HTMLHeadingElement>
) => {
    const titleProps = {
        ref,
        className: props.className
        // className: getStyle(props)
    }
    return props.secondary ? <h2 {...titleProps}>{props.children}</h2> : <h1 {...titleProps}>{props.children}</h1>
})

Title.displayName = 'Title'

export default Title
