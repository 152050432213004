import styled from '@emotion/styled'

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.bc}; 
  color: ${({ theme }) => theme.color};
`
